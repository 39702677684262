import React, { useEffect, useRef, useState } from 'react';
import easyDB from "easy-db-browser";

const { select, update } = easyDB({});

const MAIN_PASSWORD_1 = "duch"; // modra
const MAIN_PASSWORD_2 = "strach"; // zelena
const MAIN_PASSWORD_3 = "stres"; // cervena

type Loc = {
  latitude: number,
  longitude: number,
  accuracy: number,
}

type XY = { x: number, y: number };

const pointOhen: Loc = {
  latitude: 48.95667,
  longitude: 16.1192012,
  accuracy: 2,
}

const pointTelo1: Loc = {
  latitude: 48.95671,
  longitude: 16.124218,
  accuracy: 2,
};
const pointTelo2: Loc = {
  latitude: 48.9559453,
  longitude: 16.1235082,
  accuracy: 2,
};
const pointTelo3: Loc = {
  latitude: 48.9557017,
  longitude: 16.1219615,
  accuracy: 2,
};

export default function App() {

  /**
   * 0 - admin
   * 1 - správa od nemrtvého
   * 2 - přihoriva hori
   * 3 - 1min triangulace
   */
  const [state, setState] = useState(0);

  const [team, setTeam] = useState(1);

  const [input, setInput] = useState("");

  const [message, setMessage] = useState("");

  const [locBefore, setLocBefore] = useState<Loc>({ longitude: 0, latitude: 0, accuracy: 1024 });
  const [loc, setLoc] = useState<Loc | Error>(new Error("Web nemá přístup k GPS..."));

  const [admin1, setAdmin1] = useState(false);
  const [admin2, setAdmin2] = useState(false);

  function getTimeFromWithStore(): number {
    const timeFrom = localStorage.getItem("timeFrom");
    if (timeFrom) {
      return parseInt(timeFrom);
    } else {
      return new Date().getTime();
    }
  }

  const [timeDistance, setTimeDistance] = useState(10240);
  const [timeFrom, setTimeFrom] = useState(getTimeFromWithStore());

  function setTimeFromWithStore(time: number) {
    localStorage.setItem("timeFrom", time.toString());
    setTimeFrom(time);
  }

  const distPointOhen = loc instanceof Error ? 10240 : distance(WGStoM(loc), WGStoM(pointOhen));
  const distPointOhenBefore = loc instanceof Error ? 10240 : distance(WGStoM(locBefore), WGStoM(pointOhen));
  const diffDistOhen = distPointOhenBefore - distPointOhen;

  const pointTelo = team === 1 ? pointTelo1 :
    team === 2 ? pointTelo2 :
      pointTelo3;
  const distPointTelo = loc instanceof Error ? 10240 : distance(WGStoM(loc), WGStoM(pointTelo));

  const refDistPointTelo = useRef(distPointTelo);
  refDistPointTelo.current = distPointTelo;

  function setS(state: number, team: number) {
    setState(state);
    setTeam(team);
    update("db", "state", { logged: true, team, state });
  }

  // logged
  useEffect(() => {
    if (state === 0) {
      select("db", "state").then(row => {
        if (row && row.logged && typeof row.state === "number" && typeof row.team === "number") {
          setState(row.state);
          setTeam(row.team);
        }
      });
    }
  }, [state]);

  // někde stojí
  /*
  useEffect(() => {
    if (!(loc instanceof Error)) {
      const dis = distance(WGStoM(loc), WGStoM(pointAnna));
      if (Math.abs(dis) < 4) {
        setS(3);
      }
    }
  }, [loc]);
  */

  // GPS on
  useEffect(() => {
    function success(pos: any) {
      const crd = pos.coords;
      setLoc(locBefore => {
        if (!(locBefore instanceof Error)) {
          setLocBefore(locBefore);
        }
        return crd;
      });
    }
    function error(error: GeolocationPositionError) {
      setLoc(new Error(error.message));
    }

    const id = navigator.geolocation.watchPosition(success, error, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });

    return () => navigator.geolocation.clearWatch(id);
  }, []);


  return <div>
    <h1>Detektor éterického pohybu</h1>

    <p>
      {"Součadnice:  "}
      {loc instanceof Error && <>
        <span style={{ display: "inline-block", backgroundColor: "red", width: "16px", height: "16px", borderRadius: "8px", marginRight: "8px" }} />
        <br />
        {loc.message}
      </>}
      {!(loc instanceof Error) && <>
        <span style={{ display: "inline-block", backgroundColor: "green", width: "16px", height: "16px", borderRadius: "8px", marginRight: "8px" }} />
        <br />
        {loc.latitude}N <br />
        {loc.longitude}E <br />
        Přesnost: {loc.accuracy.toFixed(2)}m <br />
        Tým: {team === 1 ? "modrý" : team === 2 ? "zelený" : "červený"}
      </>}
    </p>
    <p>Pokud se hodnoty delší dobu nezměnily, přenačtěte stránku.</p>
    <hr />

    {state === 0 && <>
      <label>Heslo pro použití přístroje:</label>
      <p>Požádejte šíleného vědce, terý přístroj vyrobil o zadání.</p>
      <input type="password" value={input} onChange={e => setInput(e.target.value)}></input>
      <button onClick={() => {
        if (input === MAIN_PASSWORD_1) {
          setInput("");
          setMessage("");
          setS(1, 1);
        } else if (input === MAIN_PASSWORD_2) {
          setInput("");
          setMessage("");
          setS(1, 2);
        } else if (input === MAIN_PASSWORD_3) {
          setInput("");
          setMessage("");
          setS(1, 3);
        } else {
          setMessage("Heslo není vyplněno správně");
        }
      }}>Použít</button>
      {message && <div style={{ fontWeight: "bold", padding: "16px" }}>{message}</div>}
    </>}

    {state > 0 && <>
      <p>Co mám detekovat:</p>
      <input type="text" value={input} onChange={e => setInput(e.target.value)}></input>
      <button onClick={() => {
        const key = input.toLocaleLowerCase();

        switch (key) {
          case `tym ${MAIN_PASSWORD_1}`:
            setS(1, 1);
            return;
          case `tym ${MAIN_PASSWORD_2}`:
            setS(1, 2);
            return;
          case `tym ${MAIN_PASSWORD_3}`:
            setS(1, 3);
            return;

          case "ukaz":
            setAdmin1(true);
            return;
          case "ted":
            setAdmin2(true);
            return;

          case "prvni":
          case "ohen":
          case "oheň":
          case "hledejohen":
          case "hledej oheň":
          case "hledejohen":
          case "hledej oheň":
            setS(1, team);
            return;

          case "johana":
            setS(2, team);
            return;

          case "ok":
            setMessage("Duše právě opustila oba světy, gratuluji, povedlo se. Bež za Danou a každý ji chyťte za rameno a řekněte, že Johana je volná!!!");
            return;

          // nápovědy
          case "neohen":
            setMessage("Vidím oheň na souřadnicích: 48.9562969N, 16.1178011E. Zkus se tam podívat.");
            return;
          case "nejmeno":
            setMessage("Už s ní komunikuji. Její jméno je Johana. Zadej to do přístroje.");
            return;
          case "kde":
            setMessage(`Cítím přítomnost těla na souřadnicích: ${pointTelo.latitude}N, ${pointTelo.longitude}E. Zkus se tam podívat.`);
            return;

          default:
            setMessage("Tak tuhle nápovědu neznám.");
        }
      }}>Použít</button>
      {message && <div style={{ fontWeight: "bold", padding: "16px" }}>{message}</div>}
      <hr />
    </>}

    {/* state === 1 && <>
      <p>Pomůcka k první šíFFře.</p>
      <a href="https://photos.app.goo.gl/xiVkn6PuC1dUoD2w9" target="_blank" rel="noreferrer">https://photos.app.goo.gl/xiVkn6PuC1dUoD2w9</a>
    </> */}

    {state === 1 && <>
      <h2>Hledám oheň...</h2>
      <p>Detektor není vždy přesný, doporučuji se pohybovat rychleji, aby měření bylo přesnější.</p>
      {!(loc instanceof Error) && <>
        {admin1 && <p>
          Vzdálenost: {distPointOhen.toFixed(2)}m <br />
          Posun: {diffDistOhen.toFixed(2)}m
        </p>}

        {Math.abs(distPointOhen) < 4 ? <>
          <span className="icon">🔥🔥 HOŘÍ 🔥🔥</span>
          <p>
            Že ještě s někým budu mluvit jsem nevěřila, <br />
            ty věř nebo ne, má duše zde bohužel uhořela. <br />
            Doufala jsem, že mohu cestovat tam a zpět, <br />
            Teď bych raději opustila tento svět. <br />
            Máš k tomu správné zařízení, <br />
            ale věřit každému správné není. <br />
            Ukryla jsem na papír své jméno, <br />
            aby jsi ho složil je chtěno. <br />
            Najdeš pak, kde mé tělo leží, <br />
            ale asi již nebude svěží. <br />
            Až ten papír najdeš, není dobré tu stát, <br />
            tvá duše tu totiž může vzplát. <br />
          </p>
        </> : Math.abs(diffDistOhen) < 0.8 ? <>
          Zkus pár kroků 😊
        </> : diffDistOhen >= 0 ? <>
          <span className="icon">🎇</span><br /> Přihořívá
        </> : diffDistOhen < 0 ? <>
          <span className="icon">🌊</span><br /> Samá voda...
        </> : <>Nevím co se děje</>}
      </>}
    </>}

    {state === 2 && <>
      <h2>Hledání zakopaného těla</h2>
      {!(loc instanceof Error) && <>
        {admin2 && <p>
          Vzdálenost: <b>{distPointTelo.toFixed(2)}m</b>
        </p>}

        <Min1 timeFrom={timeFrom} onZero={() => {
          setTimeDistance(refDistPointTelo.current);
          setTimeFromWithStore(new Date().getTime());
        }} />

        <p>
          Dle posledního měření jsi byl <b style={{ display: "block", fontSize: "21px" }}>{timeDistance.toFixed(0)} m</b> daleko od cíle.
        </p>

        {Math.abs(timeDistance) < 5 && <>
          <p>
            Stojíž někde nad mým tělem. <br />
            Pro uvolnění duše je potřeba zadat kód pro vypuštění. <br />
            Johana říká, že tento kód měla, ale zakódovaný, třeba je šifra stále poblíž těla.
          </p>
        </>}
      </>}
    </>}

    <hr style={{ marginTop: 64 }} />
    <p>
      Kontakt na nápovědu, či jen technické záležitosti: <a href="tel:+420605074968">605 074 968</a>
    </p>

  </div>;
}

function Min2({ timeFrom, onZero }: { timeFrom: number, onZero: () => void }) {
  const [time, setTime] = useState(120);

  useEffect(() => {
    const i = setInterval(() => {
      const time = (timeFrom + 120000 - (new Date()).getTime()) / 1000;
      setTime(Math.round(time));
      if (time <= 0) {
        onZero();
      }
    }, 200);

    return () => clearInterval(i);
  }, [timeFrom]);

  return <div>
    Další měření za: <b>{time} s</b>
  </div>;
}

function Min1({ timeFrom, onZero }: { timeFrom: number, onZero: () => void }) {
  const [time, setTime] = useState(120);

  useEffect(() => {
    const i = setInterval(() => {
      const time = (timeFrom + 60000 - (new Date()).getTime()) / 1000;
      setTime(Math.round(time));
      if (time <= 0) {
        onZero();
      }
    }, 200);

    return () => clearInterval(i);
  }, [timeFrom]);

  return <div>
    Další měření za: <b>{time} s</b>
  </div>;
}

function sqr(x: number): number {
  return x * x;
}

function distance(from: XY, to: XY): number {
  const distance = Math.sqrt(Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2));
  return distance;
}

// 300m
const gpsCenter = [49.7209250, 15.6691614];
const gpsE = [49.7209944, 15.6732814];
const gpsN = [49.7180394, 15.6695261];
const n = gpsCenter[0] - gpsN[0];
const e = gpsCenter[1] - gpsE[1];
const rn = 300 / n;
const re = 300 / e;

function WGStoM(loc: Loc): XY {
  return {
    x: loc.latitude * re,
    y: loc.longitude * rn,
  };
}

/* from https://www.pecina.cz/krovak.html - do not work for that
function WGStoJTSK_NO(loc: Loc): XY {
  var d2r = Math.PI / 180;
  var a = 6378137.0;
  var f1 = 298.257223563;
  var dx = -570.69;
  var dy = -85.69;
  var dz = -462.84;
  var wx = 4.99821 / 3600 * Math.PI / 180;
  var wy = 1.58676 / 3600 * Math.PI / 180;
  var wz = 5.2611 / 3600 * Math.PI / 180;
  var m = -3.543e-6;

  var lat = loc.latitude;
  var lon = loc.longitude;
  var B = lat * d2r;
  var L = lon * d2r;
  // nadmořská výška
  var H = 200;

  var e2 = 1 - sqr(1 - 1 / f1);
  var rho = a / Math.sqrt(1 - e2 * sqr(Math.sin(B)));
  var x1 = (rho + H) * Math.cos(B) * Math.cos(L);
  var y1 = (rho + H) * Math.cos(B) * Math.sin(L);
  var z1 = ((1 - e2) * rho + H) * Math.sin(B);

  var x2 = dx + (1 + m) * (x1 + wz * y1 - wy * z1);
  var y2 = dy + (1 + m) * (-wz * x1 + y1 + wx * z1);
  var z2 = dz + (1 + m) * (wy * x1 - wx * y1 + z1);

  a = 6377397.15508;
  f1 = 299.152812853;
  var ab = f1 / (f1 - 1);
  var p = Math.sqrt(sqr(x2) + sqr(y2));
  e2 = 1 - sqr(1 - 1 / f1);
  var th = Math.atan(z2 * ab / p);
  var st = Math.sin(th);
  var ct = Math.cos(th);
  var t = (z2 + e2 * ab * a * (st * st * st)) / (p - e2 * a * (ct * ct * ct));

  B = Math.atan(t);
  H = Math.sqrt(1 + t * t) * (p - a / Math.sqrt(1 + (1 - e2) * t * t));
  L = 2 * Math.atan(y2 / (p + x2));

  a = 6377397.15508;
  var e = 0.081696831215303;
  var n = 0.97992470462083;
  var rho0 = 12310230.12797036;
  var sinUQ = 0.863499969506341;
  var cosUQ = 0.504348889819882;
  var sinVQ = 0.420215144586493;
  var cosVQ = 0.907424504992097;
  var alpha = 1.000597498371542;
  var k2 = 1.00685001861538;

  var sinB = Math.sin(B);
  t = (1 - e * sinB) / (1 + e * sinB);
  t = sqr(1 + sinB) / (1 - sqr(sinB)) * Math.exp(e * Math.log(t));
  t = k2 * Math.exp(alpha * Math.log(t));

  var sinU = (t - 1) / (t + 1);
  var cosU = Math.sqrt(1 - sinU * sinU);
  var V = alpha * L;
  var sinV = Math.sin(V);
  var cosV = Math.cos(V);
  var cosDV = cosVQ * cosV + sinVQ * sinV;
  var sinDV = sinVQ * cosV - cosVQ * sinV;
  var sinS = sinUQ * sinU + cosUQ * cosU * cosDV;
  var cosS = Math.sqrt(1 - sinS * sinS);
  var sinD = sinDV * cosU / cosS;
  var cosD = Math.sqrt(1 - sinD * sinD);

  var eps = n * Math.atan(sinD / cosD);
  rho = rho0 * Math.exp(-n * Math.log((1 + sinS) / cosS));

  var x = rho * Math.sin(eps);
  var y = rho * Math.cos(eps);

  return { x, y };
}
*/
